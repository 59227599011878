<template>
  <v-container fluid class="fill-height justify-center">
    <div class="d-flex flex-row align-center justify-center">
      <div class="d-flex flex-column" style="width: 100%;">
        <Logo class="mb-10 mx-auto" />
        <div style="max-width: 550px; width: 100%; margin: auto;">
          <LanguageSelector buttons class="mb-5" />
          <LoginForm />
          <PhoneSupportBox class="mt-5" />
          <CompanyLogo />
        </div>
      </div>
    </div>
  </v-container>
</template>

<route>
{
  "meta": {
    "guest": true
  }
}
</route>

<script>
import Logo from '@/components/Logo';
import LoginForm from '@/components/LoginForm';
import LanguageSelector from '@/components/LanguageSelector';
import PhoneSupportBox from '@/components/guest/PhoneSupportBox';
import CompanyLogo from '@/components/CompanyLogo.vue';

export default {
  name: 'Login',
  layout: 'guest',
  components: {
    Logo,
    LoginForm,
    PhoneSupportBox,
    CompanyLogo,
    LanguageSelector
  }
};
</script>
