<template>
  <div class="flex justify-center mt-10">
    <router-link to="/">
      <img src="../assets/Logo_Login.png" class="logo" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  created() {}
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.logo {
  width: 400px;
  height: auto;
  max-width: 100%;
  margin-top: -12px;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .logo {
    width: 70%;
    margin: auto;
  }
}

@media #{map-get($display-breakpoints, 'xl-only')} {
  .logo {
    width: 500px;
  }
}
</style>
