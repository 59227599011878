<template>
  <div>
    <v-card elevation="15">
      <ValidationObserver ref="observer" v-slot="{}">
        <v-form class="pt-3" @submit.prevent="login">
          <v-card-text class="px-6 pb-0">
            <ValidationProvider
              v-slot="{ errors }"
              name="email"
              rules="required|email"
            >
              <v-text-field
                v-model="email"
                outlined
                :label="$t('login.email_label')"
                :b-placeholder="$t('login.email_placeholder')"
                name="email"
                prepend-icon="mdi-email"
                type="email"
                :disabled="busy || disabled"
                :error-messages="errors"
                required
                :b-class="{ 'text-caption': !email }"
              />
            </ValidationProvider>
            <ValidationProvider
              v-if="requestName"
              v-slot="{ errors }"
              name="name"
              rules="required"
            >
              <v-text-field
                v-model="name"
                outlined
                :label="$t('login.name_label')"
                :b-placeholder="$t('login.name_placeholder')"
                name="name"
                prepend-icon="mdi-account"
                type="text"
                :disabled="busy || disabled"
                :error-messages="errors"
                required
              />
            </ValidationProvider>
            <!--
            <ValidationProvider
              v-slot="{ errors }"
              name="country"
              rules="required"
            >
              <v-autocomplete
                v-model="country"
                prepend-icon="mdi-map-marker"
                :items="countries"
                outlined
                label="Country"
                :disabled="busy || disabled"
                :error-messages="errors"
                required
              ></v-autocomplete>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="company"
              rules="required"
            >
              <v-text-field
                v-model="company"
                prepend-icon="mdi-office-building-marker"
                outlined
                label="Company"
                :disabled="busy || disabled"
                :error-messages="errors"
                required
              ></v-text-field>
            </ValidationProvider>
            -->
            <ValidationProvider
              v-if="usePassword"
              v-slot="{ errors }"
              name="password"
              rules="required"
            >
              <v-text-field
                v-model="password"
                outlined
                :label="$t('login.password_label')"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                :disabled="busy || disabled"
                :error-messages="errors"
                required
                @keyup.enter.prevent="login"
              />
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              name="kvkk"
              :rules="{ required: { allowFalse: false } }"
              v-if="useKvkk"
            >
              <v-checkbox
                v-model="kvkk"
                class="mt-0"
                :disabled="busy || disabled"
                :error-messages="errors"
              >
                <template slot="label">
                  <span class="text-caption" style="line-height: 1.2;">
                    By checking this box I declare that I have read and agree to
                    the terms of the
                    <TermsDialog lang="en">
                      Privacy Notice as displayed here.
                    </TermsDialog>
                  </span>
                </template>
              </v-checkbox>
            </ValidationProvider>
            <ValidationProvider
              v-if="useCaptcha"
              v-slot="{ errors }"
              name="captcha"
              rules="required"
            >
              <input v-model="captcha" name="captcha" type="hidden" disabled />
              <div class="flex justify-center mt-4">
                <vue-recaptcha
                  ref="recaptcha"
                  sitekey="6Ld56ZAfAAAAAGPaluEAi8FuyUzxnVuj_w9WtHEB"
                  theme="dark"
                  @verify="handleCaptcha"
                  @expired="handleCaptchaError"
                  @error="handleCaptchaError"
                />
              </div>
              <div class="text-red-500 text-xs text-center">
                <p v-for="i in errors" :key="i">{{ i }}</p>
              </div>
            </ValidationProvider>
            <v-alert dense v-if="message" :type="messageType" text>
              {{ $t('errors.' + msgCode) }}
            </v-alert>
          </v-card-text>
          <v-card-actions class="px-6 pb-6 pt-2">
            <v-btn
              :loading="busy"
              :disabled="busy || disabled"
              depressed
              block
              class="black--text"
              color="primary"
              large
              type="submit"
            >
              {{ $t('login.login') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </v-card>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { VueRecaptcha } from 'vue-recaptcha';
import TermsDialog from './TermsDialog';
import countries from 'country-list';

export default {
  name: 'LoginForm',
  components: {
    ValidationObserver,
    ValidationProvider,
    TermsDialog,
    VueRecaptcha
  },
  data() {
    return {
      countries: countries.getNames(),
      directLogin: false,
      useKvkk: true,
      kvkk: false,
      usePassword: false,
      requestName: false,
      busy: false,
      disabled: false,
      email: '',
      password: '',
      name: '',
      country: '',
      company: '',
      message: null,
      msgCode: null,
      messageType: null,
      useCaptcha: true,
      captcha: ''
    };
  },
  created() {
    if (this.$route.query.password) {
      this.password = this.$route.query.password;
      this.usePassword = true;
    }

    if (this.$route.query?.email) {
      this.email = this.$route.query.email;
      this.requestName = false;
    }

    if (this.$route.query?.accept) {
      this.kvkk = true;
      this.kvkk2 = true;
      setTimeout(() => {
        this.login();
      }, 500);
    }
  },
  methods: {
    handleCaptcha(response) {
      this.captcha = response;
    },
    handleCaptchaError() {
      this.captcha = '';
    },
    async login() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return;
      }

      this.busy = true;
      try {
        if (this.usePassword || this.directLogin) {
          const data = {};
          if (this.email) {
            data.email = this.email;
          }
          if (this.password) {
            data.password = this.password;
          }
          if (this.requestName) {
            data.name = this.name;
          }
          if (this.country) {
            data.country = this.country;
          }
          if (this.company) {
            data.company = this.company;
          }
          if (this.captcha) {
            data.captcha = this.captcha;
          }
          await this.$store.dispatch('auth/login', data);
          this.$router.push(this.$route.params?.redirect || '/');
        } else {
          const data = {
            email: this.email,
            returnUrl: window.location.origin + '/enter-code',
            lang: this.$i18n.locale
          };
          if (this.captcha) {
            data.captcha = this.captcha;
          }
          await this.$store.dispatch('auth/requestOtp', data);
          this.$router.push({
            path: '/enter-code',
            query: { email: this.email }
          });
        }
      } catch (e) {
        this.messageType = 'error';
        this.message = e.response?.data?.message || e.message;
        this.msgCode = e.response?.data?.type;
      } finally {
        this.busy = false;
      }
    }
  }
};
</script>
